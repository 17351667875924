import Highlight from "components/Highlight/Highlight";
import { LoadingData } from "components/LoadingData/LoadingData";
import { isEmpty, uniqBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Resources } from "resources/Resources";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "../Icon/Icon";
import "./InputSearch.scss";
import { Row } from "react-bootstrap";

export type InputSearchEntity = "customer";

export type InputSearchOption = {
  content: string;
  type?: string;
  entity: InputSearchEntity;
  highlight: string;
  relativePath: string | undefined;
  itemId: string;
  document: any;
}

export type InputSearchGroup = {
  label: string;
  options: InputSearchOption[];
  continuationToken?: string;
}

type InputSearchProps = {
  name?: string;
  title?: string;
  className?: string;
  placeholder?: string;
  isLoading: boolean;
  type?: 'text' | 'number' | 'hidden' | 'file';
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  optionsGroups: InputSearchGroup[] | undefined;
  min?: number;
  max?: number;
  focus?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  accept?: string;
  id?: string;
  step?: number;
  pattern?: string;
  onFocus?: (input: React.FocusEvent<any>) => void;
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: (value: string) => void;
  onKeyPress?: (input: any) => void;
  onOptionSelect: (option: InputSearchOption) => void;
  onShowMore: (token: string) => void;
  errorMessage?: unknown;
  alertMessage?: string;
};

export const InputSearch: React.FC<InputSearchProps> = (props, _ref) => {
  const ref = useRef(null);
  const textInput = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const {
    focus,
    className,
    value = '',
    isLoading,
    optionsGroups,
    onClear,
    onChange,
    onOptionSelect,
    onShowMore,
    errorMessage,
    alertMessage,
    ...restProps
  } = props;

  const [_value, setValue] = useState<string>(value);
  const [_focus, setFocus] = useState<boolean>(!!focus);
  const [pristine, setPristine] = useState<boolean>(true);
  const [expanded, setExpanded] = useState(false);

  const [recentOptions, setRecentOptions] = useState<InputSearchOption[]>([]);
  const [lastLocation, setLastLocation] = useState<string>("");
  const [pageChanged, setPageChanged] = useState<boolean>(false);

  useEffect(() => {
    if(lastLocation != location.pathname){
      setLastLocation(location.pathname)
      setPageChanged(true);
      setValue('');
      onClear('');
    }
  }, [location]);

  useEffect(() => {
    if (_focus) {
      textInput?.current?.focus();
      setPageChanged(false);
    } 
  }, [_focus]);

  useEffect(() => {
    if (textInput?.current && _value) setPristine(false);
  }, [_value]);

  const isRecentOptionsVisible = useMemo<boolean>(() => !_value && _focus && recentOptions.length > 0, [_focus, _value, recentOptions]);

  const isOptionsVisible = useMemo<boolean>(() => {
    if (!optionsGroups) return false;
    const groupsNotEmpty = Object.keys(optionsGroups).some(key => !isEmpty(optionsGroups[key as any].options));
    return groupsNotEmpty && !isLoading;
  }, [_focus, optionsGroups, isLoading, isRecentOptionsVisible]);

  const isNoOptionsVisible = useMemo<boolean>(() => {
    const groupsEmpty = optionsGroups !== undefined && Object.keys(optionsGroups).every(key => isEmpty(optionsGroups[key as any].options));
    return _focus && groupsEmpty && !pristine && !!_value && !isLoading;
  }, [isLoading, optionsGroups, _value]);

  const handleClear = () => {
    setValue('');
    onClear('');
    setTimeout(() => {setFocus(true);}, 100);
    setTimeout(() => {setExpanded(true);}, 200);
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
    onChange(evt);
  }

  const handleFocus = (_: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    setExpanded(true);
  }

  const handleBlur = (_: React.FocusEvent<HTMLInputElement>) => {
    // This exists to avoid hiding options before option click is triggered due to onBlur executing before onClick
    setTimeout(() => {
      setFocus(false);
      setExpanded(false);
    }, 500);
  }

  const handleOptionSelect = (opt: InputSearchOption) => {
    const _recents = uniqBy([{...opt, highlight: _value} ,...recentOptions], ({highlight, ...rest}) => JSON.stringify(rest));
    setRecentOptions(_recents);
    setValue(opt.content);
    onOptionSelect(opt);
  }

  const handleClearRecent = () => {
    setRecentOptions([]);
    setTimeout(() => setFocus(true), 100);
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !(ref?.current as any).contains(event.target as Element)) {
        setExpanded(false);
      }
    }
    const removeWindowEventListener = () => document.removeEventListener("mousedown", handleClickOutside);
    const addWindowEventListener = () => document.addEventListener("mousedown", handleClickOutside);

    (expanded ? addWindowEventListener : removeWindowEventListener)();
    return () => removeWindowEventListener();
  }, [ref, expanded]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div ref={ref} className={`InputSearch ${className}`}>
      <div className={`InputSearch__input-container`} >
        <input ref={textInput} {...{ ...restProps }} autoComplete="off" value={_value} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
        <Icon className="InputSearch__icon" name="search-v2" />
        {_value && <Icon name="close" className="InputSearch__clear" onClick={handleClear} />}
      </div>

      {/* No results message */}
      {!alertMessage && (isNoOptionsVisible || errorMessage) ?
        <div className="InputSearch__options">
        <div className="InputSearch__options-container">
          <div className="InputSearch__options-group">
            <label className="InputSearch__option-group-label">
              {errorMessage ?
                Resources.get("CommandCenter", "searchBarUnavailable", "label")
                :
                Resources.get("InputSearch", "noResults", "label")}
            </label>
          </div>
        </div>
      </div> : null}

      
      {/* alert message */}
      {alertMessage &&
        <div className="InputSearch__options">
        <div className="InputSearch__options-container">
          <div className="InputSearch__options-group">
            <label className="InputSearch__option-group-label">
              {alertMessage}
            </label>
          </div>
        </div>
      </div>}

      {/* Recent Options */}
      {isRecentOptionsVisible &&
        <div className="InputSearch__options">
          {recentOptions && recentOptions?.length > 0 &&
            <div className="InputSearch__options-container">
              <div key={`InputSearch__options-group--recent}`} className="InputSearch__options-group">
              <label className="InputSearch__option-group-label">
                <span>{Resources.get("InputSearch", "recents", "label")}</span>
                <span className="InputSearch__option-group-clear" onClick={handleClearRecent}>{Resources.get("InputSearch", "clearRecent", "label")}</span>
              </label>
                {recentOptions.map((option) => (
                  <div key={`InputSearch__options--${uuidv4()}-${option.content}}`} className="InputSearch__option" onClick={() =>{ setFocus(false); handleOptionSelect(option)}}>
                    <Row>
                       <Highlight text={`${option.document.dsName} - ${option.document.cdAccount}`} highlight={_value} className="InputSearch__option-detail-name"/>                       
                    </Row>
                    <Row style={{marginBottom: '-6px'}}>
                      <Highlight text={option.document.dsAddress} highlight={_value} className="InputSearch__option-detail-address"/>
                    </Row>
                  </div>
                ))
                }
              </div>
            </div>
          }
        </div>
      }

      {/* Search Options */}
      {(expanded && isOptionsVisible) &&
        <div className="InputSearch__options">
          <div className="InputSearch__options-container">
            {optionsGroups && optionsGroups?.length > 0 &&
            <>
              {optionsGroups.map(({ label, options, continuationToken }) => (
                <div key={`InputSearch__options-group--${label}`} className="InputSearch__options-group">
                  {<label className="InputSearch__option-group-label">{label}</label>}
                  {options.map((option) => (
                    <div id={`InputSearch__options--${uuidv4()}-${option.content}-${label}`} key={`InputSearch__options--${uuidv4()}-${option.content}}-${label}`} className="InputSearch__option" onClick={() => {setExpanded(false); handleOptionSelect(option);}}>
                     <Row>
                       <Highlight text={`${option.document.dsName} - ${option.document.cdAccount}`} highlight={_value} className="InputSearch__option-detail-name"/>                       
                    </Row>
                    <Row style={{marginBottom: '-6px'}}>
                      <Highlight text={option.document.dsAddress} highlight={_value} className="InputSearch__option-detail-address"/>
                    </Row> 
                    </div>
                  ))}
                  {
                    continuationToken && 
                    <div className="InputSearch__options-group InputSearch__more" onClick={() => onShowMore(continuationToken)}>
                      <div className="InputSearch__more-message">
                        <Icon className="InputSearch__more-icon" name="search-v2" />
                        <span>{Resources.get("InputSearch", "showMoreFor", "label")} <Highlight text={_value} highlight={_value} /></span>
                      </div>
                      <span className="InputSearch__more-cta">{Resources.get("InputSearch", "showMoreCTA", "label")}</span>
                    </div>
                  }
                </div>
              ))}
            </>}
            </div>
        </div>
      }

      {(!pageChanged && isLoading && !isOptionsVisible) &&
        <div className="InputSearch__options">
          <LoadingData
            className="ActionButton__spinner"
            data-testid="loadingSpinner"
            animation="border"
          />
        </div>
      }
    </div>
  );
};
